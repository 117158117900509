.root{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}


.table-padding {
    width: fit-content;
    padding-right: 15px;
    overflow: visible;
}

@media (min-width: 992px) { /* Assuming 'lg' corresponds to 992px */
    .table-padding {
        width: 100%;
        padding-right: 0px;
        overflow: auto;
    }
}
.btn-red-maroon{
    background-color: #C00000;
    color: #fff;
}
.btn-red-maroon:hover {
    background-color: #C00000;
    border-color: #C00000;
    color: #fff;
}
.table.table-style-2 td{
    font-size: 15px !important;
}
@font-face {
    font-family: 'Calibri-bold';
    src: url('../fonts/Calibri-Bold.woff2') format('woff2'),
        url('../fonts/Calibri-Bold.woff') format('woff');

}

@font-face {
    font-family: 'Calibri-italic';
    src: url('../fonts/Calibri-Italic.woff2') format('woff2'),
        url('../fonts/Calibri-Italic.woff') format('woff');


}

@font-face {
    font-family: 'Calibri';
    src: url('../fonts/Calibri.woff2') format('woff2'),
        url('../fonts/Calibri.woff') format('woff');
  
}

@font-face {
    font-family: 'Calibri-light-italic';
    src: url('../fonts/Calibri-LightItalic.woff2') format('woff2'),
        url('../fonts/Calibri-LightItalic.woff') format('woff');

}

@font-face {
    font-family: 'Calibri-light';
    src: url('../fonts/Calibri-Light.woff2') format('woff2'),
        url('../fonts/Calibri-Light.woff') format('woff');
}

@font-face {
    font-family: 'Calibri-bold-italic';
    src: url('../fonts/Calibri-BoldItalic.woff2') format('woff2'),
        url('../fonts/Calibri-BoldItalic.woff') format('woff');
}

